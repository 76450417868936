import { Summary } from "$src/components/summary/summary";
import { useFunnelQuestions } from "$src/hooks/useFunnelQuestions";
import { useValidatedParam } from "$src/hooks/useValidatedParam";
import { api } from "$src/lib/api";
import { COMPETITOR_AVERAGE_ID, COMPETITOR_COLORS } from "$src/lib/consts";
import { regionalize } from "$src/lib/regionalization";
import { Question } from "$src/models/Funnel";
import {
  useFunnelTimelineCompetitorSummary,
  useFunnelTimelineQuestion,
} from "$src/queries/funnel";
import { useAvailableFilters } from "$src/queries/useAvailableFilters";
import { useAccount } from "$src/stores/useAccount";
import { useFilters } from "$src/stores/useFilters";
import { Download } from "$src/views/download/download";
import { FilterBar } from "$src/views/filter-bar/filter-bar";
import { useTimelineConfig } from "$src/views/timeline/lib/useTimelineConfig";
import { useChartMilestones } from "$src/views/timeline/lib/utils";
import { Timeline } from "$src/views/timeline/timeline";
import { constantCase, sentenceCase } from "change-case";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FunnelQuestionType } from "@tracksuit/frontend/schemas";

export const FunnelTimelineMetricRoute = () => {
  const availableQuestions = useFunnelQuestions({});
  const validateQuestion = useCallback(
    (q: FunnelQuestionType) => availableQuestions.includes(q),
    [availableQuestions],
  );
  const question = useValidatedParam<FunnelQuestionType>({
    param: "question",
    deseralize: constantCase,
    validate: validateQuestion,
    fallback: "/funnel/timeline/metric/prompted-awareness",
  });
  const { demographics, dateRange, brandIdList } = useFilters((s) => s.filters);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [hydrated, setHydrated] = useState(false);
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const timelineRef = useRef(null);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const timelineConfig = useTimelineConfig((s) => s.config);
  const { data, loading } = useFunnelTimelineQuestion({
    question,
  });
  const {
    data: summary,
    loading: summaryLoading,
    error: summaryError,
  } = useFunnelTimelineCompetitorSummary({
    question,
    enabled: summaryOpen,
  });
  const getChartColor = (
    brand: NonNullable<typeof data>["brands"][0],
    i: number,
  ) => {
    if (brand.brandId === account?.brandId) {
      return "var(--color-purple-500)";
    }

    if (brand.brandId === COMPETITOR_AVERAGE_ID) {
      return "var(--color-purple-700)";
    }

    return (
      COMPETITOR_COLORS[i]?.primary ??
      COMPETITOR_COLORS[i - COMPETITOR_COLORS.length + 1]?.primary
    );
  };
  const chartData = useMemo(
    () =>
      data?.brands.map((brand, i) => ({
        label: brand.brandName ?? "Brand",
        id: String(brand.brandId),
        color: getChartColor(brand, i) ?? "",
        metrics: brand.metrics,
        difference: brand.difference,
      })),
    [data],
  );
  const actionsDisabled = useMemo(() => {
    if (availableFilters.dates && availableFilters.dates.length < 3) {
      return true;
    }
    return !timelineConfig.categoryPenetration && !chartData?.length;
  }, [availableFilters, chartData, timelineConfig]);
  const milestones = useChartMilestones(
    data?.brands.map(({ brandId }) => brandId) ?? [],
  );

  const getExportUrl = async () => {
    if (!account || !dateRange.start || !dateRange.end || !question) {
      throw new Error("Account or date range not set");
    }

    const res = await api.exports.timelineBrands.query({
      accountBrandId: account.accountBrandId,
      demographicFilter: demographics.map(({ filter }) => filter),
      demographicFilterIds: demographics.map(({ id }) => id),
      waveStartDate: dateRange.start,
      waveEndDate: dateRange.end,
      format: "XLSX",
      questionType: question,
      hiddenBrands: [],
      includeCategoryPenetration: timelineConfig.categoryPenetration,
    });

    return res?.exportUrl;
  };

  useEffect(() => {
    setHydrated(false);
  }, [account]);

  useEffect(() => {
    if (!account || hydrated) {
      return;
    }

    setSelectedBrands([String(account.brandId)]);
    setHydrated(true);
  }, [account, hydrated]);

  return (
    <>
      <FilterBar
        filters={{
          demographic: true,
          date: { type: "range" },
          brand: { multiSelect: true, includeCompetitorAverage: true },
        }}
        actions={{
          download: () => setDownloadOpen(true),
          summarise: () => setSummaryOpen((s) => !s),
          copyAsImage: timelineRef,
        }}
        actionsDisabled={actionsDisabled}
        quality={data?.quality}
      />
      <Timeline
        title={
          question
            ? `${sentenceCase(
                regionalize(
                  Question[question] as string,
                  account?.geography.name,
                ),
              )} over time`
            : ""
        }
        dataUnselected={!brandIdList.length}
        data={chartData}
        quality={data?.quality}
        milestones={milestones}
        loading={loading}
        ref={timelineRef}
        activeDatasets={selectedBrands}
        onActiveDatasetsChange={setSelectedBrands as any}
        categoryPenetration={data?.categoryPenetration ?? null}
      />
      <Download
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        fetcher={getExportUrl}
        heading="Downloading the timeline response"
        label="Timeline"
        format="XLSX"
      >
        This is currently only available in Excel format.
      </Download>
      <Summary
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        summaries={[
          {
            content: summary?.summary,
            loading: summaryLoading,
            error: summaryError,
          },
        ]}
      />
    </>
  );
};
